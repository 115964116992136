// src/asset/img/services/Parebrise.jsx
'use client';
import Jpg128 from '@/ui/client/img/Jpg128';

const ParebriseImg = () => {
  return (
    <Jpg128
      jpgSrc='/img/bg/web3.jpg'
      webpSrc='/img/bg/web3.webp'
      altText='Nous rachetons tout types de véhicules et toutes marques automobile'
      className='secubgBackground'
    />
  );
};

export default ParebriseImg;
